<template>
  <div id="app" >
  

    <div class="logo-container">
      <a href="https://memorialparquemaceio.com.br">
      <img src="./assets/logo.svg" alt="Logo" style="width:250px"/>
      </a>
    </div>


    <div class="card-container">
      <div v-if="cardRows && cardRows.length">
        <CardItem
          v-for="card in cardRows"
          :key="card.id"
          :nome="card.falecido"
          :capela="card.capela"
          :sepultamento="card.sepultamento"
          :horario="card.sepultamento"
          :cardColor="cardColor"
        />
      </div>
      <div v-else>
      
        Não encontrado óbitos
      </div>
    </div><br/>

    <footer class="footer">
      <div class="footer-content">
        <img src="./assets/LOGOSVG4.svg" alt="Grupo Parque das Flores" style="width: 100px; height: 90px;">
        <p style="color: white; text-align: center;">©2023 Empresa do Grupo Parque -
          Todos os direitos reservados <br/>
        </p>
      </div>
    </footer>



  </div>
</template>

<script>
import CardItem from './components/CardItem.vue'
import Contrato from './services/contrato'

export default {
  name: 'App',
  components: {
    CardItem
  },

  data() {
    return {
      cardColor: "darkgreen",
      cardRows: [
        // ...
      ],
    };
  },

  mounted() {
    this.lista();
  },

  methods:{

    lista(){
        

          Contrato.lista(this.search_name, "MEMORIAL PARQUE MACEIO", this.currentPage, this.pageSize)
              .then(response => {
                        if (response.status === 200) {
                         
                          this.cardRows = response.data; // Extrair os dados da resposta paginada
                        }
                      })
                      .catch(error => {
                        // Lida com erros
                        console.error('Erro a', error);
                      }) .finally(() => {
                        // Após o término do download, oculte o diálogo de progresso
                        this.loading = false;
                      });
            },


  } 
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 40px;
}

.logo-container {
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 70vh; /* Define uma altura máxima para a área de cards */
  overflow-y: auto; /* Adiciona uma barra de rolagem vertical, se necessário */
  position: relative;
  z-index: 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size:14px
}
.footer {
  background-color: #254828;
  position: fixed; /* Definir o footer como posição fixa */
  bottom: 0; /* Ancorar o footer na parte inferior */
  left: 0;
  width: 100%;
}

</style>
