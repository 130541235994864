<template>
    <div class="card" :style="{ backgroundColor: cardColor }">
      <div class="card-image-container">
        <i class="fas fa-cross card-icon white-icon"></i>
      </div>
      <div class="card-content">
        <p style="font-size:22px;height:60px">{{ nome.toUpperCase() }}</p>
        <p>Sepultamento: {{ formatarData(sepultamento) }}</p>
        <p>Horário: {{ formatarHora(horario) }}</p>
        <p>{{ capela }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      nome: String,
      capela: String,
      sepultamento: String,
      horario: String,
      cardColor: String,
    },
    methods: {


    formatarHora(valor) {
        const [, hora] = valor.split(' ');
        return hora;
    },

    formatarData(horario) {
      const [data] = horario.split(' ');
      return data;
    },


  },
  };
  </script>
  
  <style>
  /* Estilize o card conforme necessário */
  .card {
    display: inline-block;
    width: 420px;
    height: 250px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .card-icon {
    margin-top:10px;
    font-size: 26px; /* Tamanho da fonte para o ícone */
  }
  
  .white-icon {
    color: white; /* Cor branca para o ícone */
  }
  
  .card-content {
    color: white;
    padding: 5px;
  }

  .card-image {
    width: 100px;
    height: 100px;
    margin: 10px auto;
  }
  
  .card-content {
    color: white;
    padding: 5px;
  }
  </style>
  